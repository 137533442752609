import { useEffect, useMemo, useState, useRef } from "react";
import { Button, Card, Form, FormControl, FormGroup, FormLabel, InputGroup, Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";

function StartQueue({ws}){
    const [errMsg, setErrMsg] = useState(null)

    return useMemo(()=>{
        return (
            <div>
                <p style={{marginBottom: "8em"}}>排队系统还没开启哦！</p>
                <Form onSubmit={(e)=>{
                    e.preventDefault()
                    e.stopPropagation()
                    const formData = new FormData(e.target)
                    const max = parseInt(formData.get("max"))

                    if (isNaN(max)){
                        console.log("error")
                        setErrMsg("请输入数字")
                        return
                    }

                    if (ws && ws.readyState === ws.OPEN)
                        ws.send(JSON.stringify({e: "queue", subE: "open", on: true, max: max}))
                }}>
                    <FormGroup className="mb-2">
                        <FormLabel>队伍最大人数</FormLabel>
                        <FormControl type="text" name="max" isInvalid={errMsg!==null} onChange={()=>{ setErrMsg(null) }}/>
                        <FormControl.Feedback type="invalid">{errMsg}</FormControl.Feedback>
                    </FormGroup>
                    <Button type="submit">开启系统！</Button>
                </Form>
            </div>
        )
    }, [ws, errMsg])
}

function RunningQueue({ws, current, queueLength, count, max}){
    const [loginInfoCopied, setLoginInfoCopied] = useState(false)
    const [authcodeCopied, setauthcodeCopied] = useState(false)
    const [showExpandModal, setShowExpandModal] = useState(false)

    const [addQuotasErrMsg, setAddQuotasErrMsg] = useState("")

    const loginInfoRef = useRef(null)
    const authcodeRef = useRef(null)

    const [nextButton, expandButton, closeButton] = useMemo(()=>{
        return [
            <Button disabled={queueLength === 0} onClick={()=>{
                if (ws)
                    ws.send(JSON.stringify({e: "queue", subE: "getNext"}))
            }}>
                    {queueLength===0?"暂时还没有人排队哦":"下一个"}
            </Button>,
            <Button onClick={()=>{
                setShowExpandModal(true)
            }}>
                增加名额
            </Button>,
            <Button variant="danger" onClick={()=>{
                if (ws)
                    ws.send(JSON.stringify({e: "queue", subE: "open", on: false}))
            }}>
                关闭排队系统
            </Button>
        ]
    }, [ws, queueLength])

    const AddQuotasModal = useMemo(()=>{
        return (
            <Modal show={showExpandModal} onHide={()=>{ setShowExpandModal(false) }}>
                <ModalHeader closeButton>
                    <ModalTitle>增加名额</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        const formData = new FormData(e.target)
                        const quotas = parseInt(formData.get("quotas"))
                        if (isNaN(quotas)){
                            setAddQuotasErrMsg("请输入数字哦！")
                            return
                        }
                        if (!ws || ws.readyState !== ws.OPEN){
                            setAddQuotasErrMsg("服务器已断开，请重新进入后台哦。")
                            return 
                        }
                        
                        ws.send(JSON.stringify({ e: "queue", subE: "expandQueue", quotas: quotas }))
                        setShowExpandModal(false)
                    }}>
                        <FormGroup className="mb-3">
                            <FormLabel>名额数量</FormLabel>
                            <FormControl type="text" name="quotas" onChange={(e)=>{
                                setAddQuotasErrMsg("")
                                e.target.value = e.target.value.replace(/[^\d]/g, "")
                            }}/>
                        </FormGroup>
                        <p style={{color: "red", display: addQuotasErrMsg===""?"none":"block"}}>{addQuotasErrMsg}</p>
                        <Button type="submit">增加</Button>
                    </Form>
                </ModalBody>
            </Modal>
        )
    }, [ws, showExpandModal, addQuotasErrMsg])

    return useMemo(()=>{
        if (current === null){
            return (
                <>
                    {AddQuotasModal}
                    <div style={{display: "flex", gap: 16, marginBottom: "16px"}}>
                        <InputGroup style={{flex: 1}}>
                            <InputGroup.Text>排队人数</InputGroup.Text>
                            <InputGroup.Text style={{flex: 1}}>{queueLength}个</InputGroup.Text>
                        </InputGroup>
                        <InputGroup style={{flex: 1}}>
                            <InputGroup.Text>剩余名额</InputGroup.Text>
                            <InputGroup.Text style={{flex: 1}}>{((max - count) - queueLength)}个</InputGroup.Text>
                        </InputGroup>
                        <InputGroup style={{flex: 1}}>
                            <InputGroup.Text>已完成</InputGroup.Text>
                            <InputGroup.Text style={{flex: 1}}>{count}个</InputGroup.Text>
                        </InputGroup>
                    </div>
                    <p style={{marginBottom: "8em"}}>还没提取用户。</p>
                    <div style={{display: "flex", gap: 16}}>
                        {nextButton}
                        {expandButton}
                        {closeButton}
                    </div>
                </>
            )
        }

        return (
            <>
                {AddQuotasModal}
                <div style={{display: "flex", gap: 16, marginBottom: "16px", flexWrap: "wrap"}}>
                        <InputGroup style={{flex: 1, minWidth: 200}}>
                            <InputGroup.Text>排队人数</InputGroup.Text>
                            <InputGroup.Text style={{flex: 1}}>{queueLength}个</InputGroup.Text>
                        </InputGroup>
                        <InputGroup style={{flex: 1, minWidth: 200}}>
                            <InputGroup.Text>剩余名额</InputGroup.Text>
                            <InputGroup.Text style={{flex: 1}}>{((max - count) - queueLength)}个</InputGroup.Text>
                        </InputGroup>
                        <InputGroup style={{flex: 1, minWidth: 200}}>
                            <InputGroup.Text>已完成</InputGroup.Text>
                            <InputGroup.Text style={{flex: 1}}>{count}个</InputGroup.Text>
                        </InputGroup>
                </div>
                <FormGroup className="mb-2">
                    <FormLabel>直播间用户名</FormLabel>
                    <FormControl type="text" value={current.username} readOnly disabled/>
                </FormGroup>
                <FormGroup className="mb-2">
                    <FormLabel>登入资料</FormLabel>
                    <InputGroup>
                        <FormControl ref={loginInfoRef} type="text" value={current.loginInfo} readOnly/>
                        <Button disabled={loginInfoCopied} variant={loginInfoCopied?"danger":"primary"} onClick={(e)=>{
                            if (!navigator.clipboard){
								loginInfoRef.current.select()
								document.execCommand("copy", true)
								setLoginInfoCopied(true)
							}
							else{
								navigator.clipboard.writeText(current.loginInfo).then(()=>{
									setLoginInfoCopied(true)
								})
							}
                            setTimeout(()=>{
                                setLoginInfoCopied(false)
                            }, 500)
                        }}>
                            {loginInfoCopied?"已复制":"复制"}
                        </Button>
                    </InputGroup>
                </FormGroup>
                <FormGroup className="mb-4">
                    <FormLabel>验证码</FormLabel>
                    <InputGroup>
                        <FormControl ref={authcodeRef} type="text" value={!current.authcode?"":current.authcode} placeholder={"用户还未填入验证码"} readOnly/>
                        <Button disabled={authcodeCopied} variant={authcodeCopied?"danger":"primary"} onClick={(e)=>{
                            if (!navigator.clipboard){
                                authcodeRef.current.select()
                                document.execCommand("copy", true)
                                setauthcodeCopied(true)
                            }
                            else{
                                navigator.clipboard.writeText(current.loginInfo).then(()=>{
                                    setauthcodeCopied(true)
                                })
                            }
                            setTimeout(()=>{
                                setauthcodeCopied(false)
                            }, 500)
                        }}>
                            {authcodeCopied?"已复制":"复制"}
                        </Button>
                    </InputGroup>
                </FormGroup>
                <div style={{display: "flex", gap: 16}}>
                    {count<max?nextButton:null}
                    {expandButton}
                    {closeButton}
                </div>
            </>
        )
    }, [current, queueLength, count, max, loginInfoCopied, authcodeCopied, AddQuotasModal, nextButton, expandButton, closeButton])
}

function Queue(){
    const [isopen, setIsOpen] = useState(false)
    const [max, setMax] = useState(0)
    const [count, setCount] = useState(0)
    const [queueLength, setQueueLength] = useState(null)
    const [current, setCurrent] = useState(null)
    const [ws, setWS] = useState(null)

    const wsStartedConn = useRef(false)
    useEffect(()=>{
        if (wsStartedConn.current === false){
            wsStartedConn.current = true

            const websocket = new WebSocket(
                (window.location.protocol==="https:"?"wss:":"ws:") + "//" + window.location.hostname + "/api/ws"
            )
            websocket.onopen = () => {
				websocket.send(JSON.stringify({e: "subscribeQueue"}))
			}
            websocket.onclose = () => {
                wsStartedConn.current = false
                setWS(null)
            }
            websocket.onmessage = (msg) => {
				if (msg.data === "") return
				const json = JSON.parse(msg.data)
				if (json.e === "subscribeQueue"){
                    if (json.subE === "init"){
                        setIsOpen(json.isopen)
                        setCurrent(json.current)
                        setQueueLength(json.length)
                        setMax(json.max)
                        setCount(json.count)
                    }
                    else if (json.subE === "currentChange")
                        setCurrent(json.current)
                    else if (json.subE === "lengthChange")
                        setQueueLength(json.length)
                    else if (json.subE === "maxChange")
                        setMax(json.max)
                    else if (json.subE === "countChange")
                        setCount(json.count)
				}
			}
            setWS(websocket)
        }
    }, [ws])

    return useMemo(()=>{
        if (!isopen && current && queueLength.length){
            setCurrent(null)
            setQueueLength({length: null})
        }

        return (
            <Card style={{maxWidth: 800, margin: "auto"}}>
                <Card.Header>排队系统后台</Card.Header>
                <Card.Body>
                    {
                        !isopen ? <StartQueue ws={ws}/> : // 未开始
                        <RunningQueue ws={ws} current={current} queueLength={queueLength} count={count} max={max}/>
                    }
                </Card.Body>
            </Card>
        )
    }, [ws, isopen, current, queueLength, count, max])
}

export default Queue