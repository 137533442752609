import { useCallback, useMemo, useState } from "react";
import { Decimal } from "decimal.js"
import { Form, FormControl, FormGroup, FormLabel, Row, Col, Button, Card, Table, InputGroup } from "react-bootstrap";
import request from "../../../functions/request";

function CreateDraw({setCurrKey}){
	const [participantList, setParticipantList] = useState([])
	const [giftList, setGiftList] = useState([])

	const [participantDone, participantTable] = useMemo(()=>{
		const isDone = participantList.length > 0 && participantList.filter((x)=>{ return x.name === "" || typeof(x.times) === "string" }).length === 0

		return [
			isDone,
			(
				<Card>
					<Card.Header>参与者&nbsp;<span style={{color: isDone?"":"red"}}>{!isDone?"(请完成参与者列表)":""}</span></Card.Header>
					<Card.Body>
						<Table bordered size="sm">
							<thead>
								<tr>
									<td style={{width: "40%"}}>名称</td>
									<td style={{width: "40%"}}>抽奖次数</td>
									<td>
										<div className="d-grid">
											<Button style={{padding: 0}}
												onClick={()=>{
													setParticipantList((old)=>{ return [...old, {name: '', times: 0}] })
												}}
											>
												新增
											</Button>
										</div>
									</td>
								</tr>
							</thead>
							<tbody>
								{
									participantList.map((x, i)=>{
										return (
											<tr key={i}>
												<td style={{width: "40%"}}>
													<FormGroup>
														<FormControl style={{padding: "0px 4px"}} value={x.name} isInvalid={x.name===""}
															onChange={(e)=>{
																if (/['"\\]/g.test(e.target.value)) return

																setParticipantList((old)=>{
																	return old.map((y)=>{
																		if (y !== x) return y
																		return {
																			name: e.target.value,
																			times: y.times
																		}
																	})
																})
															}}
														/>
														<FormControl.Feedback type="invalid">名称不可为空</FormControl.Feedback>
													</FormGroup>
												</td>
												<td style={{width: "40%"}}>
													<FormGroup>
														<FormControl style={{padding: "0px 4px"}} value={x.times} isInvalid={typeof(x.times)==="string"}
															onChange={(e)=>{
																const value = parseInt(e.target.value)
																if (isNaN(value) && e.target.value!=="") return
																			
																setParticipantList((old)=>{
																	return old.map((y)=>{
																		if (y !== x) return y
																		return {
																			name: y.name,
																			times: !isNaN(value)?value:""
																		}
																	})
																})
															}}
														/>
														<FormControl.Feedback type="invalid">请输入数字作为抽奖次数</FormControl.Feedback>
													</FormGroup>
												</td>
												<td>
													<div className="d-grid">
														<Button variant="danger" style={{padding: 0}}
															onClick={()=>{ setParticipantList((old)=>{ return old.filter((y)=>y!==x) }) }}
														>
															删除
														</Button>
													</div>
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			)
		]
	}, [participantList])

	const [giftListDone, giftTable] = useMemo(()=>{
		const ProbabilityDecimal = giftList.reduce((p,c)=>{
			if ((!p.toNumber && isNaN(p)) || typeof(c.probability) === "string") return NaN
			return p.plus(c.probability)
		}, new Decimal(0))
		const TotalProbability = ProbabilityDecimal.toNumber?ProbabilityDecimal.toNumber():ProbabilityDecimal
		const isDone = !isNaN(TotalProbability) && TotalProbability <= 100 && giftList.length > 0 && giftList.filter((x)=>{return x.name==="" || x.probability === 0 || typeof(x.probability) === "string" || new Decimal(x.probability).mod(0.001).toNumber() > 0}).length === 0

		return [
			isDone,
			(
				<Card>
					<Card.Header>奖品&nbsp;<span style={{color: isDone?"":"red"}}>{TotalProbability>100?"(奖品总机率超过100)":(!isDone?"(请完成奖品列表)":"")}</span></Card.Header>
					<Card.Body>
						<Table bordered size="sm">
							<thead>
								<tr>
									<td style={{width: "40%"}}>奖品名称</td>
									<td style={{width: "40%"}}>得奖机率(%)</td>
									<td>
										<div className="d-grid">
											<Button style={{padding: 0}}
												onClick={()=>{
													setGiftList((old)=>{ return [...old, {name: '', probability: 0}] })
												}}
											>
												新增
											</Button>
										</div>
									</td>
								</tr>
							</thead>
							<tbody>
								{
									giftList.map((x, i)=>{
										return (
											<tr key={i}>
												<td style={{width: "40%"}}>
													<FormGroup>
														<FormControl style={{padding: "0px 4px"}} value={x.name} isInvalid={x.name===""}
															onChange={(e)=>{
																if (/['"\\]/g.test(e.target.value)) return

																setGiftList((old)=>{
																	return old.map((y)=>{
																		if (y !== x) return y
																		return {
																			name: e.target.value,
																			probability: y.probability
																		}
																	})
																})
															}}
														/>
														<FormControl.Feedback type="invalid">名称不可为空</FormControl.Feedback>
													</FormGroup>
												</td>
												<td style={{width: "40%"}}>
													<FormGroup>
														<FormControl style={{padding: "0px 4px"}} value={x.probability} isInvalid={typeof(x.probability) === "string" || parseFloat(x.probability)<=0 || new Decimal(x.probability).mod(0.001).toNumber() > 0}
															onChange={(e)=>{
																const value = parseFloat(e.target.value)
																if (isNaN(value) && e.target.value !== "") return

																setGiftList((old)=>{
																	return old.map((y)=>{
																		if (y !== x) return y
																		return {
																			name: y.name,
																			probability: !isNaN(value)&&value.toString()===e.target.value?value:e.target.value
																		}
																	})
																})
															}}
														/>
														<FormControl.Feedback type="invalid">
															{
																parseFloat(x.probability)<=0?"机率不可以使用负数或零":(
																	(typeof(x.probability)!=="string"&&new Decimal(x.probability).mod(0.001).toNumber()>0)?"小数后不能超过两位数":
																	"请输入数字作为抽奖次数"
																)
															}
														</FormControl.Feedback>
													</FormGroup>
												</td>
												<td>
													<div className="d-grid">
														<Button variant="danger" style={{padding: 0}}
															onClick={()=>{ setGiftList((old)=>{ return old.filter((y)=>y!==x) }) }}
														>
															删除
														</Button>
													</div>
												</td>
											</tr>
										)
									})
								}
								<tr>
									<td>奖品飞咯</td>
									<td>{isNaN(TotalProbability)?"请先填好其他奖品的机率":(new Decimal(100).minus(TotalProbability).toNumber())}</td>
									<td></td>
								</tr>
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			)
		]
	}, [giftList])

	const formHanle = useCallback((e)=>{
		e.preventDefault()
		e.stopPropagation()
		if (!participantDone || !giftListDone) return

		console.log(participantList, giftList)
		const formData = new FormData(e.target)
		const eventName = formData.get("eventName")
		const lastOneProbability = new Decimal(100).minus(giftList.reduce((p,c)=>{
			if ((!p.toNumber && isNaN(p)) || typeof(c.probability) === "string") return NaN
			return p.plus(c.probability)
		}, new Decimal(0))).toNumber()

		request.post(window.location.protocol+"//"+window.location.hostname+"/api/admin/luckydraw/createDraw", {
			name: eventName,
			participants: participantList,
			gifts: [...giftList, {name: "奖品飞咯", probability: lastOneProbability}]
		}).then(resp=>{
			if (resp.status){
				setParticipantList([])
				setGiftList([])
				setCurrKey('nonStartDraw')
			}
			else
				window.alert(resp.reason??"未知错误")
		})
	}, [setCurrKey, participantDone, giftListDone, participantList, giftList])

	return useMemo(()=>{
		return (
			<Form onSubmit={formHanle}>
				<FormGroup className="mb-2" style={{width: "100%", maxWidth: 600, margin: "auto"}}>
					<FormLabel>抽奖活动名称</FormLabel>
					<InputGroup>
						<FormControl type="text" name="eventName" required/>
						<Button type="submit">创建</Button>
					</InputGroup>
				</FormGroup>
				<Row style={{width: "100%", maxWidth: 1200, margin: "auto"}}>
					<Col md={6}>
						{participantTable}
					</Col>
					<Col md={6}>
						{giftTable}
					</Col>
				</Row>
			</Form>
		)
	}, [formHanle, participantTable, giftTable])
}

export default CreateDraw