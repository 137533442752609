import { Container } from "react-bootstrap"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { useMemo } from "react"

function NavBar(){
    return useMemo(()=>{
        return (
            <Navbar style={{backgroundColor: "#ffe9f4", marginBottom: 8}} variant="light" expand="md">
                <Container fluid>
                    <Navbar.Brand>
                        <img alt="logo" src="/images/logo.png" className="d-inline-block align-top" style={{height: 30}}/>&nbsp;酥喂酥喂
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="top-navbar-nav"/>

                    <Navbar.Collapse id="top-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">主页</Nav.Link>
                            <Nav.Link href="/luckydraw-offline">抽奖系统(单机)</Nav.Link>
                            <Nav.Link href="/queue">排队系统</Nav.Link>
                            <Nav.Link href="/admin">后台管理</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }, [])
}

export default NavBar