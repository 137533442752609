import { useCallback, useMemo, useState } from "react";
import { Button, Card, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";

import { sha256 } from "js-sha256";

function LoginPanel(){
    const [errMsg, setErrMsg] = useState("")

    const formAction = useCallback((e)=>{
        e.preventDefault()
        e.stopPropagation()
        
        const formData = new FormData(e.target)
        const username = formData.get("username")
        const password = formData.get("password")
        
        let errMsg = ""
        if (username.length === 0)
            errMsg = "用户名不可为空"
        else if (password.length === 0)
            errMsg = "密码不可为空"
        else if (/[^A-Za-z0-9]/g.test(username))
            errMsg = "用户名不可存在英文字母及数字以外的字符"
        

        setErrMsg(errMsg)
        
        if (errMsg.length !== 0)
            return
        
        fetch(window.location.protocol+"//"+window.location.hostname+"/api/admin/login", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                username: username,
                password: sha256(password)
            })
        }).then(r=>r.json()).then((resp)=>{
            if (resp.status === false){
                setErrMsg(resp.reason??"密码错误")
                return
            }
            window.location.reload()
        })
    }, [])

    return useMemo(()=>{
        return (
            <Card style={{maxWidth: 500, width: "100%", margin: "30vh auto"}}>
                <Card.Header>登入后台系统</Card.Header>
                <Card.Body>
                    <Form onSubmit={formAction}>
                        <FormGroup className="mb-2">
                            <FormLabel>用户名</FormLabel>
                            <FormControl type="text" name="username"/>
                        </FormGroup>
                        <FormGroup className="mb-2">
                            <FormLabel>密码</FormLabel>
                            <FormControl type="password" name="password"/>
                        </FormGroup>
                        <p style={{color: 'red'}}>{errMsg}</p>
                        <Button type="submit">登入</Button>
                    </Form>
                </Card.Body>
            </Card>
        )
    }, [errMsg, formAction])
}

export default LoginPanel