import { useState, useEffect, useMemo, useRef } from "react";
import request from "../../../functions/request";
import timehandle from "../../../functions/timehandle"

import { Card, Table, Col, Row, Button, ToastContainer } from "react-bootstrap";
import AutoHideToast from "../../../components/AutoHideToast";

function ParticipantRow({x, checkingDrawID}){
	const [copied, setCopied] = useState(false)
	const taRef = useRef(null)

	const link = useMemo(()=>{
		return window.location.protocol+"//"+window.location.hostname+"/luckydraw/"+checkingDrawID+"/"+x.id+"/"+x.key
	}, [checkingDrawID, x])

	return useMemo(()=>{
		return (
			<tr key={x.id}>
				<td>{x.name}</td>
				<td>{x.times}</td>
				<td>
					<div className="d-grid">
						{
							!navigator.clipboard?
							<textarea ref={taRef} style={{display: "none"}} defaultValue={link}></textarea>:
							null
						}
						<Button style={{padding: 0}} variant={copied?"danger":"primary"} onClick={()=>{
							if (!navigator.clipboard){
								taRef.current.style.display = "block"
								taRef.current.select()
								document.execCommand("copy", true)
								taRef.current.style.display = "none"
								setCopied(true)
							}
							else{
								navigator.clipboard.writeText(link).then(()=>{
									setCopied(true)
								})
							}
							setTimeout(()=>{setCopied(false)}, 300)
						}}>{copied?"已复制":"复制"}</Button>
					</div>
				</td>
			</tr>
		)
	}, [x, link, copied])
}

function CheckDraw({checkingDrawID, setCheckingDrawID, ended=false, currentWS}){
	const [loading, setLoading] = useState(true)
	const [ws, setWS] = useState(null)
	const requestSended = useRef(false)
	const connectCreated = useRef(false)

	const eventNameRef = useRef(null)
	const [participantList, setParticipantList] = useState([])
	const [giftList, setGiftList] = useState([])
	const [recordList, setRecordList] = useState([])
	const [toasts, setToast] = useState([])

	const participantListRef = useRef([])
	const giftListRef = useRef([])

	useEffect(()=>{
		participantListRef.current = participantList
		giftListRef.current = giftList
	}, [participantList, giftList])

	useEffect(()=>{
		if (!requestSended.current){
			requestSended.current = true

			request.get(window.location.protocol+"//"+window.location.hostname+"/api/admin/luckydraw/loadDraw", {id: checkingDrawID, withID: true}).then(resp=>{
				setLoading(false)
				eventNameRef.current = resp.name
				setParticipantList(resp.participants.sort((a,b)=>{return a.id - b.id}))
				setGiftList(resp.gifts.sort((a,b)=>{return a.id - b.id}))
				setRecordList(resp.records.sort((a,b)=>{return a.id - b.id}))
			})
		}
	}, [checkingDrawID])

	useEffect(()=>{
		if (!loading && !ws && !connectCreated.current && !ended){
			connectCreated.current = true
			let websocket = new WebSocket(
				(window.location.protocol==="https:"?"wss:":"ws:") + "//" + window.location.hostname + "/api/ws"
			)
			websocket.onopen = () => {
				websocket.send(JSON.stringify({e: "subscribeDraw", draw_id: checkingDrawID}))
			}
			websocket.onmessage = (msg) => {
				if (msg.data === "") return
				const json = JSON.parse(msg.data)
				if (json.e === "subscribeDraw"){
					if (json.record){
						setRecordList((old)=>[...old, json.record])
						setToast((old)=>{
							const participant = participantListRef.current.find((x)=>{return x.id === json.record.participant_id})
							const gift = giftListRef.current.find((x)=>{return x.id === json.record.gift_id})
							if (!participant || !gift) return old

							return [
								...old,
								<AutoHideToast key={old.length} title={participant.name} time={timehandle.epochToString(json.record.time, timehandle.TF.hms)} 
									msg={participant.name + (gift.name==="奖品飞咯"?"的奖品飞咯":("抽到了"+gift.name))}
								/>
							]
						})

						setParticipantList((old)=>{
							return old.map((x)=>{
								if (x.id !== json.record.participant_id) return x
								return {
									id: x.id,
									name: x.name,
									times: x.times - 1,
									key: x.key
								}
							})
						})
					}
				}
			}
			setWS(websocket)
			currentWS.current = websocket
		}
	}, [loading, ended, ws, checkingDrawID, currentWS])

	const participantTable = useMemo(()=>{
		if (loading || ended) return null
		return (
			<Card className="mb-2">
				<Card.Header>参与者</Card.Header>
				<Card.Body>
					<Table bordered size="sm">
						<thead>
							<tr>
								<td>名称</td>
								<td>剩余次数</td>
								<td>抽奖链接</td>
							</tr>
						</thead>
						<tbody>
							{
								participantList.map((x)=><ParticipantRow key={x.id} x={x} checkingDrawID={checkingDrawID}/>)
							}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		)
	}, [loading, ended, checkingDrawID, participantList])
	
	const giftTable = useMemo(()=>{
		if (loading) return <></>
		return (
			<Card className="mb-2">
				<Card.Header>奖品</Card.Header>
				<Card.Body>
					<Table bordered size="sm">
						<thead>
							<tr>
								<td>奖品名称</td>
								<td>奖品机率(%)</td>
							</tr>
						</thead>
						<tbody>
							{
								giftList.map((x)=>{
									return (
										<tr key={x.id}>
											<td>{x.name}</td>
											<td>{x.probability}</td>
										</tr>
									)
								})
							}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		)
	}, [loading, giftList])

	const recordTable = useMemo(()=>{
		return (
			<Card>
				<Card.Header>抽奖记录</Card.Header>
				<Card.Body>
					<Table bordered hover size="sm">
						<thead>
							<tr>
								<th>系统编号</th>
								<th>时间</th>
								<th>中奖者</th>
								<th>奖品</th>
							</tr>
						</thead>
						<tbody>
							{
								(!ended?recordList.slice().reverse():recordList).map((x)=>{
									const participant = participantList.find((y)=>{ return y.id === x.participant_id })
									const gift = giftList.find((y)=>{ return y.id === x.gift_id })

									return (
										<tr key={x.id}>
											<td>{x.id}</td>
											<td>{timehandle.epochToString(x.time, timehandle.TF.ymdhms)}</td>
											<td>{participant.name}</td>
											<td>{gift.name}</td>
										</tr>
									)
								})
							}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		)
	}, [ended, recordList, participantList, giftList])

	return useMemo(()=>{
		if (loading) return <h3 style={{textAlign: "center"}}>加载中。。。</h3>

		return (
			<>
				<div className="mb-2" style={{display: "flex"}}>
					<div style={{flex: 1}}></div>
					<h3 style={{flex: 2, textAlign: 'center'}}>{eventNameRef.current}</h3>
					<div style={{flex: 1, textAlign: "right"}}>
						<Button onClick={()=>{
							if (currentWS.current) {
								currentWS.current.close()
								currentWS.current = null
							}
							setCheckingDrawID(-1)
						}}>返回</Button>
					</div>
				</div>
				<Row>
					<Col lg={3}>
						{giftTable}
						{participantTable}
					</Col>
					<Col lg={9}>
						{recordTable}
					</Col>
				</Row>
				<ToastContainer position="top-center">
					{toasts}
				</ToastContainer>
			</>
		)
	}, [loading, setCheckingDrawID, currentWS, participantTable, giftTable, recordTable, toasts])
}

export default CheckDraw