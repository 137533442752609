import { useMemo } from "react";
import NavBar from "../components/Navbar";

import { Container } from "react-bootstrap"

function MainPage(){
	return useMemo(()=>{
		return (
			<>
				<NavBar/>
				<Container id="main-body" style={{height: "auto"}}>
					<div style={{textAlign: "center"}}>
						<img alt="big logo" src="/images/logo.png" style={{width: "80vw", maxWidth: 600}}/>
					</div>
				</Container>
			</>
		)
	}, [])
}

export default MainPage