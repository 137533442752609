import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import AdminPage from './pages/AdminPage';
import LuckyDrawPage from './pages/LuckyDrawPage';
import LuckyDrawOfflinePage from './pages/LuckyDrawOfflinePage';
import Err404Page from './pages/404';
import QueuePage from './pages/QueuePage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Router>
			<Routes>
				<Route path="/luckydraw/:draw_id/:participant_id/:auth_key" element={<LuckyDrawPage/>}/>
				<Route path='/admin' element={<AdminPage/>} />
				<Route path='/luckydraw-offline' element={<LuckyDrawOfflinePage/>} />
				<Route path='queue' element={<QueuePage/>} />
				<Route path='/' element={<MainPage/>} />
				<Route path="*" element={<Err404Page/>} />
			</Routes>
		</Router>
	</React.StrictMode>
);