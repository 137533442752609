const get = (endpoint="", data={})=>{
    const url  = new URL(endpoint)
    Object.keys(data).forEach((key)=>{
        url.searchParams.append(key, data[key])
    })
    return fetch(url, {method: "GET"}).then(r=>r.json())
}

const post = (endpoint="", data={})=>{
    return fetch(endpoint, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    }).then(r=>r.json())
}

const request = {
    get, post
}

export default request