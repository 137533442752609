import { useState } from "react";
import { useMemo } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

function DangerBtn({name="按钮", dangerMessage="这个按钮很危险噢，确定执行吗？", onClick=()=>{}, style={}}){
	const [showModal, setShowModal] = useState(false)

	return useMemo(()=>{
		return (
			<>
				<Button variant="danger" onClick={()=>{ setShowModal(true) }}>{name}</Button>
				<Modal show={showModal} onHide={()=>{ setShowModal(false) }}>
					<ModalHeader>警告</ModalHeader>
					<ModalBody>
						{dangerMessage}
					</ModalBody>
					<ModalFooter style={{display: "flex", gap: 8}}>
						<Button onClick={()=>{
							setShowModal(false)
							onClick()
						}}>确定</Button>
						<Button variant="danger" onClick={()=>{ setShowModal(false) }}>取消</Button>
					</ModalFooter>
				</Modal>
			</>
		)
	}, [name, dangerMessage, onClick, style, showModal])
}

export default DangerBtn