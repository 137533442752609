import React, { useState, useMemo } from "react";
import { Table, Button, FormControl, InputGroup, FormSelect, Modal, ModalTitle, ModalBody, ModalHeader, Form, FormGroup, FormLabel } from "react-bootstrap";

/** @param {React.Dispatch<React.SetStateAction<string>>} setSelectParticipantListID */
function ParticipantPanel({selectParticipantListID="", setSelectParticipantListID, participantList={"":[]}, setParticipantList}){
	const [showAddList, setShowAddList] = useState(false)

	const SelectedParticipantList = useMemo(()=>{
		if (selectParticipantListID === null) return null
		return participantList[selectParticipantListID]
	}, [selectParticipantListID, participantList])

	const ParticipantControlTable = useMemo(()=>{
		if (SelectedParticipantList === null) return null
		return (
			<Table bordered>
				<thead>
					<tr>
						<td>参与者</td>
						<td>抽奖数</td>
						<td>
							<div className="d-grid">
								<Button style={{padding: 0}}
									onClick={()=>{
										setParticipantList((old)=>{
											const newObj = Object.assign({}, old)
											newObj[selectParticipantListID] = [...old[selectParticipantListID], {name: "", times: 0}]
											return newObj
										})
									}}
								>
									添加
								</Button>
							</div>
						</td>
					</tr>
				</thead>
					<tbody>
						{
							SelectedParticipantList.map((x, i)=>{
								return (
									<tr key={i}>
										<td style={{width: "50%"}}>
											<FormControl style={{padding: "0px 4px"}} type="text" value={x.name}
												onChange={(e)=>{
													setParticipantList((old)=>{
														const newObj = Object.assign({}, old)
														newObj[selectParticipantListID] = old[selectParticipantListID].map((y)=>{
															if (x !== y) return y
															return {
																name: e.target.value,
																times: x.times
															}
														})
														return newObj
													})
												}}
											/>
										</td>
										<td style={{width: "25%"}}>
											<FormControl style={{padding: "0px 4px"}} type="text" value={x.times}
												onChange={(e)=>{
													setParticipantList((old)=>{
														const newObj = Object.assign({}, old)
														newObj[selectParticipantListID] = old[selectParticipantListID].map((y)=>{
															if (x !== y) return y
															const value = parseInt(e.target.value)
															return {
																name: x.name,
																times: isNaN(value)?e.target.value:parseInt(e.target.value)
															}
														})
														return newObj
													})
												}}
											/>
										</td>
										<td>
											<div className="d-grid">
												<Button style={{padding: 0}} variant="danger" onClick={()=>{
													setParticipantList((old)=>{
														const newObj = Object.assign({}, old)
														newObj[selectParticipantListID] = old[selectParticipantListID].filter((y)=>{ return x!==y })
														return newObj
													})
												}}>删除</Button>
											</div>
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</Table>
		)
	}, [selectParticipantListID, SelectedParticipantList])

	return useMemo(()=>{
		return (
			<div id="participant-panel">
				<InputGroup className="mb-2">
					<InputGroup.Text>当前列表</InputGroup.Text>
					<FormSelect value={selectParticipantListID??undefined} onChange={(e)=>{
						setSelectParticipantListID(e.target.value)
						if (localStorage.participantList)
							setParticipantList(JSON.parse(localStorage.participantList))
					}}>
						{
							Object.keys(participantList).map((x, i)=>{
								return <option key={i} value={x}>{x}</option>
							})
						}
					</FormSelect>
					<Button onClick={()=>{setShowAddList(true)}}>添加</Button>
					<Button variant="danger" onClick={()=>{
						setSelectParticipantListID(null)
						setParticipantList((old)=>{
							const newObj = Object.assign({}, old)
							delete(newObj[selectParticipantListID])
							return newObj
						})
					}}>
						删除
					</Button>
				</InputGroup>
				<Modal show={showAddList} onHide={()=>{setShowAddList(false)}}>
					<ModalHeader closeButton>
						<ModalTitle>添加参与者列表</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Form onSubmit={(e)=>{
							e.preventDefault();
							e.stopPropagation();
							const formData = new FormData(e.currentTarget)
							const listName = formData.get("listName")
							if (Object.keys(participantList).findIndex((x)=>x===listName) !== -1){
								window.alert("此列表名称已存在")
								return
							}
							setParticipantList((old)=>{
								const newObj = Object.assign({}, localStorage.participantList?JSON.parse(localStorage.participantList):old)
								newObj[listName] = []
								return newObj
							})
							setSelectParticipantListID(listName)
							setShowAddList(false)
						}}>
							<FormGroup className="mb-2">
								<FormLabel>列表名称</FormLabel>
								<FormControl type="text" name="listName"/>
							</FormGroup>
							<Button type="submit">新增</Button>
						</Form>
					</ModalBody>
				</Modal>
				{ParticipantControlTable}
			</div>
		)
	}, [showAddList, selectParticipantListID, participantList, ParticipantControlTable])
}

export default ParticipantPanel