import { useRef } from "react";
import { useState, useMemo } from "react";
import { Tabs, Tab } from "react-bootstrap"
import CreateDraw from "./CreateDraw";
import EndedDraw from "./EndedDraw";
import NonStartDraw from "./NonStartDraw";
import StartedDraw from "./StartedDraw";

function LuckyDraw(){
	const [currKey, setCurrKey] = useState("createDraw")
	const currentWS = useRef(null)

	return useMemo(()=>{
		if (currentWS.current) {
			currentWS.current.close()
			currentWS.current = null
		}

		return (
			<>
				<Tabs activeKey={currKey} variant="pills" className="mb-2" onSelect={(k)=>{setCurrKey(k)}}>
					<Tab eventKey="createDraw" title="创建抽奖"/>
					<Tab eventKey="nonStartDraw" title="未开启抽奖"/>
					<Tab eventKey="StartedDraw" title="进行中抽奖"/>
					<Tab eventKey="EndedDraw" title="已结束抽奖"/>
				</Tabs>
				{
					currKey==="createDraw"?<CreateDraw setCurrKey={setCurrKey}/>:
					currKey==="nonStartDraw"?<NonStartDraw setCurrKey={setCurrKey} />:
					currKey==="StartedDraw"?<StartedDraw currentWS={currentWS}/>:
					currKey==="EndedDraw"?<EndedDraw currentWS={currentWS}/>:
					null
				}
			</>
		)
	}, [currKey])
}

export default LuckyDraw