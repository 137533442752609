import { useEffect, useMemo, useRef, useState } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";

import NavBar from "../components/Navbar";
import LoginPanel from "./AdminPage/LoginPanel";
import LuckyDraw from "./AdminPage/LuckyDraw";
import Queue from "./AdminPage/Queue";

var sha256 = require('js-sha256').sha256;

function AdminPage(){
    const [isLogin, setIsLogin] = useState(false)
    const [currKey, setCurrKey] = useState("luckydraw")
    const CheckLoginSended = useRef(false)
    const UpdateCookieTimer = useRef(null)

    useEffect(()=>{
        if (!isLogin && CheckLoginSended.current === false){
            CheckLoginSended.current = true
            fetch(window.location.protocol + "//" + window.location.hostname + "/api/admin/login/status").then(r=>r.json()).then((resp)=>{
                if (resp.status){
                    setIsLogin(true)
                    if (UpdateCookieTimer.current === null){
                        UpdateCookieTimer.current = setInterval(()=>{
                            fetch(window.location.protocol + "//" + window.location.hostname + "/api/admin/login/status").then(r=>r.json()).then((resp)=>{
                                if (resp.status)
                                    setIsLogin(true)
                                else {
                                    setIsLogin(false)
                                    clearInterval(UpdateCookieTimer.current)
                                }
                            })
                        }, 60000)
                    }
                }
            })
        }
    }, [isLogin])

    return useMemo(()=>{
        return (
            <>
                <NavBar/>
                <Container fluid>
                    {
                        !isLogin?<LoginPanel/>:(
                            <Tabs activeKey={currKey} onSelect={(k)=>{setCurrKey(k)}} className="mb-2">
                                <Tab eventKey="title" disabled title="后台管理系统"></Tab>
                                <Tab eventKey="luckydraw" title="抽奖系统"><LuckyDraw/></Tab>
                                <Tab eventKey="queue" title="排队系统"><Queue/></Tab>
                            </Tabs>
                        )
                    }
                </Container>
            </>
        )
    }, [isLogin, currKey])
}

export default AdminPage