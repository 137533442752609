import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import { Decimal } from 'decimal.js'

import { Container, Row, Col, InputGroup, FormSelect } from "react-bootstrap";
import { LuckyWheel } from '@lucky-canvas/react'

function LuckyDrawPanel({giftList={"":[]}, participantList={"":[]}, setParticipantList, setHistory}){
    const participantListRef = useRef(participantList)
    const selectParticipantListIDRef = useRef(null)
    const selectParticipantIndexRef = useRef(null)

    const [selectParticipantListID, setSelectParticipantListID] = useState(null)
    const [selectGiftListID, setSelectGiftListID] = useState(null)
    const [selectParticipantIndex, setSelectParticipantIndex] = useState(0)

    const [notEmptyParticipantList, notEmptyGiftList] = useMemo(()=>{
        participantListRef.current = participantList
        return [
            Object.keys(participantList).filter((key)=>{return participantList[key].length > 0}),
            Object.keys(giftList).filter((key)=>{
                if (giftList[key].length === 0) return false
                for (let i = 0; i < giftList[key].length; i++)
                    if (typeof(giftList[key][i].probability) !== "number")
                        return false
                return true
            })
        ]
    }, [participantList, giftList])

    useEffect(()=>{
        selectParticipantListIDRef.current = selectParticipantListID
        if (selectParticipantListID !== null && notEmptyParticipantList.findIndex((x)=>x===selectParticipantListID) === -1)
            setSelectParticipantListID(null)
        if (selectParticipantListID === null && notEmptyParticipantList.length > 0)
            setSelectParticipantListID(notEmptyParticipantList[0])
    }, [selectParticipantListID, notEmptyParticipantList])
    useEffect(()=>{
        setSelectParticipantIndex(0)
    }, [selectParticipantListID])
    useEffect(()=>{
        selectParticipantIndexRef.current = selectParticipantIndex
    }, [selectParticipantIndex])

    useEffect(()=>{
        if (selectGiftListID !== null && notEmptyGiftList.findIndex((x)=>x===selectGiftListID) === -1)
            setSelectGiftListID(null)
        if (selectGiftListID === null && Object.keys(notEmptyGiftList).length > 0)
            setSelectGiftListID(notEmptyGiftList[0])
    }, [selectGiftListID, notEmptyGiftList])

    const FullGiftListRef = useRef([])
    const FullGiftList = useMemo(()=>{
        if (selectGiftListID === null) return []
        if (!giftList[selectGiftListID]) return []
        if (notEmptyGiftList.findIndex((x)=>x===selectGiftListID) === -1) return []

        let totalProbability = 0
		giftList[selectGiftListID].forEach((x)=>{
			totalProbability += x.probability
		})

        return [...giftList[selectGiftListID], {name: "奖品飞喽", message: "这么低的概率都让你抽到了 没办法 奖品飞喽～小倒霉蛋", probability: 100 - totalProbability}]
    }, [giftList, notEmptyGiftList, selectGiftListID])
    const ShuffledGiftListRef = useRef([])
    const ShuffledGiftList = useMemo(()=>{
        FullGiftListRef.current = FullGiftList
        const list = []
        while (list.length < FullGiftList.length){
            const notSelectedItem = FullGiftList.filter((x)=>{ return list.findIndex((y)=>{ return y.item === x }) === -1 })
            const randomIndex = Math.floor(Math.random() * notSelectedItem.length)
            list.push({
                probability: (
                    list.length===0?notSelectedItem[randomIndex].probability:(new Decimal(list[list.length-1].probability).add(notSelectedItem[randomIndex].probability).toNumber())),
                item: notSelectedItem[randomIndex],
                originIndex: FullGiftList.findIndex((x)=>{ return x === notSelectedItem[randomIndex] })
            })
        }
        return list
    }, [FullGiftList])
    useEffect(()=>{
        ShuffledGiftListRef.current = ShuffledGiftList
    }, [ShuffledGiftList])

    const luckydraw = useRef(null)
    const [prizes, blocks, buttons] = useMemo(()=>{
        const colors = ["#ffced9", "#cff0ec", "#d2eddd", "#c2d3fd", "#fecdc1", "#eee8d1"]

        return [
            FullGiftList.map((x, i)=>{
                return {
                    background: (FullGiftList.length-1===i?"#e2e1e1":colors[i%colors.length]),
                    fonts: [{text: x.name}],
                    originIndex: i
                }
            }),
            [
                { padding: '10px', background: '#ffc0cb' }
            ],
            [
                { radius: '30%', background: '#617df2' },
                { radius: '27.5%', background: '#afc8ff' },
                {
                    radius: '25%', background: '#869cfa',
                    pointer: true,
                    //imgs: [{ src: "/images/logo.png", top: "-100%", width: '80%', height: "80%"}],
                    fonts: [{ text: '开始', top: '-10px' }]
                }
            ]
        ]
    }, [FullGiftList])

    const onStart = useCallback(()=>{
        if (luckydraw.current === null || selectParticipantListIDRef.current === null || ShuffledGiftListRef.current.length === 0) return
        if (participantListRef.current[selectParticipantListIDRef.current][selectParticipantIndexRef.current].times <= 0) {
            window.alert("没有抽奖机会了")
            return
        }

        luckydraw.current.play()
        setTimeout(()=>{
            const value = Math.random() * 100
            let originIndex = 0
            for (let i = 0; i < ShuffledGiftListRef.current.length; i++){
                if (value < ShuffledGiftListRef.current[i].probability){
                    originIndex = ShuffledGiftListRef.current[i].originIndex
                    console.log(value, originIndex)
                    break
                }
            }
            luckydraw.current.stop(originIndex)
        }, 1000)
    }, [])
    const onEnd = useCallback((e)=>{
        setHistory((old)=>{
            return [
                ...old,
                {
                    time: new Date().getTime(),
                    participant: participantListRef.current[selectParticipantListIDRef.current][selectParticipantIndexRef.current].name,
                    gift: FullGiftListRef.current[e.originIndex].name
                }
            ]
        })
        setParticipantList((old)=>{
            const newObj = JSON.parse(JSON.stringify(old))
            newObj[selectParticipantListIDRef.current][selectParticipantIndexRef.current].times = newObj[selectParticipantListIDRef.current][selectParticipantIndexRef.current].times -1
            return newObj
        })
    }, [])

    return useMemo(()=>{
        return (
            <Container fluid className="mb-4">
                <h2>抽奖系统</h2>
                <Row className="mb-2">
                    <Col md={6} xxl={4} className="mb-2">
                        <InputGroup>
                            <InputGroup.Text>参与名单</InputGroup.Text>
                            <FormSelect disabled={notEmptyParticipantList.length === 0} value={selectParticipantListID??undefined} onChange={(e)=>{ setSelectParticipantListID(e.target.value) }}>
                                {
                                    notEmptyParticipantList.map((key)=>{
                                        if (participantList[key].length === 0) return null
                                        return <option key={key} value={key}>{key}</option>
                                    })
                                }
                            </FormSelect>
                        </InputGroup>
                    </Col>
                    <Col md={6} xxl={4} className="mb-2">
                        <InputGroup>
                            <InputGroup.Text>礼物列表</InputGroup.Text>
                            <FormSelect disabled={notEmptyGiftList.length === 0} value={selectGiftListID??undefined} onChange={(e)=>{ setSelectGiftListID(e.target.value) }}>
                                {
                                    notEmptyGiftList.map((key)=>{
                                        if (giftList[key].length === 0) return null
                                        return <option key={key} value={key}>{key}</option>
                                    })
                                }
                            </FormSelect>
                        </InputGroup>
                    </Col>
                    <Col xxl={4} className="mb-2">
                        <InputGroup>
                            <InputGroup.Text>参与者</InputGroup.Text>
                            <FormSelect value={selectParticipantIndex??undefined} disabled={selectParticipantListID===null || !participantList[selectParticipantListID]}
                                onChange={(e)=>{ setSelectParticipantIndex(parseInt(e.target.value)) }}
                            >
                                {
                                    selectParticipantListID===null?null:
                                    (participantList[selectParticipantListID]??[]).map((x, i)=>{
                                        if (x.name === "") return null
                                        if (x.times === 0) return <option key={i} value={i} disabled={x.times === 0}>{x.name}(抽奖次数已用完)</option>
                                        return <option key={i} value={i} disabled={x.times === 0}>{x.name}</option>
                                    })
                                }
                            </FormSelect>
                            {
                                selectParticipantListID===null?null:
                                (!participantList[selectParticipantListID])?null:
                                <InputGroup.Text>剩余 {(participantList[selectParticipantListID][selectParticipantIndex]??{times:0}).times} 次机会</InputGroup.Text>
                            }
                        </InputGroup>
                    </Col>
                </Row>
                <div style={{margin: "0 auto", width: "fit-content", height: "25rem"}}>
                    {   selectGiftListID===null?null:
                        <LuckyWheel ref={(r)=>{luckydraw.current = r}}
                            width="25rem" height="25rem"
                            blocks={blocks} prizes={prizes} buttons={buttons}
                            onStart={onStart} onEnd={onEnd}
                        />
                    }
                </div>
            </Container>
        )
    }, [
        prizes, blocks, buttons, onStart, onEnd,
        giftList, participantList, notEmptyParticipantList, notEmptyGiftList, selectParticipantListID, selectGiftListID, selectParticipantIndex
    ])
}

export default LuckyDrawPanel
