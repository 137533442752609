const TF = {
    hm:     0,
    hms:    1,
    ym:     2,
    ymd:    3,
    ymdhm:  4,
    ymdhms: 5
}

const epochToString = (epoch = 0, tf = TF.hms)=>{
    const time = new Date(epoch)
    const year = time.getFullYear()
    const month = ("0"+(time.getMonth()+1)).slice(-2)
    const day = ("0"+(time.getDate())).slice(-2)

    const hour = ("0"+time.getHours()).slice(-2)
    const min = ("0"+time.getMinutes()).slice(-2)
    const sec = ("0"+time.getSeconds()).slice(-2)

    if (tf === TF.hm)
        return hour+":"+min
    if (tf === TF.hms)
        return hour+":"+min+":"+sec
    if (tf === TF.ym)
        return year+"-"+month
    if (tf === TF.ymd)
        return year+"-"+month+"-"+day
    if (tf === TF.ymdhm)
        return year+"-"+month+"-"+day + " " + hour+":"+min
    if (tf === TF.ymdhms)
        return year+"-"+month+"-"+day + " " + hour+":"+min+":"+sec
}

const timehandle = {
    TF, epochToString
}

export default timehandle