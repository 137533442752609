import { useState, useMemo } from "react";
import { Table, Button, FormControl, InputGroup, FormSelect, Modal, ModalTitle, ModalBody, ModalHeader, Form, FormGroup, FormLabel } from "react-bootstrap";

function GiftPanel({selectGiftListID="", setSelectGiftListID, giftList={"":[]}, setGiftList}){
	const [showAddList, setShowAddList] = useState(false)

	const SelectedGiftList = useMemo(()=>{
		if (selectGiftListID === null) return null
		return giftList[selectGiftListID]
	}, [selectGiftListID, giftList])

	const FullGiftList = useMemo(()=>{
		if (SelectedGiftList === null) return null;

		let totalProbability = 0
		SelectedGiftList.forEach((x)=>{
			if (typeof(x.probability) === "number")
				totalProbability += x.probability
			else
				totalProbability = NaN
		})
		
		if (isNaN(totalProbability)) return [...SelectedGiftList, {name: "奖品飞喽", probability: NaN}]
		return [...SelectedGiftList, {name: "奖品飞喽", message: "这么低的概率都让你抽到了 没办法 奖品飞喽～小倒霉蛋", probability: 100 - totalProbability}]
	}, [SelectedGiftList])

	const GiftControlTable = useMemo(()=>{
		if (FullGiftList === null) return null
		return (
			<Table bordered>
				<thead>
					<tr>
						<td>奖品</td>
						<td>机率(%)</td>
						<td>
							<div className="d-grid">
								<Button style={{padding: 0}}
									onClick={()=>{
										setGiftList((old)=>{
											const newObj = Object.assign({}, old)
											newObj[selectGiftListID] = [...old[selectGiftListID], {name: "", probability: ""}]
											return newObj
										})
									}}
								>
									添加
								</Button>
							</div>
						</td>
					</tr>
				</thead>
					<tbody>
						{
							FullGiftList.map((x, i)=>{
								return (
									<tr key={i}>
										<td style={{width: "50%"}}>
											{
												FullGiftList.length===i+1?x.name:
													<FormControl style={{padding: "0px 4px"}} type="text" value={x.name}
														onChange={(e)=>{
															setGiftList((old)=>{
																const newObj = Object.assign({}, old)
																newObj[selectGiftListID] = old[selectGiftListID].map((y)=>{
																	if (x !== y) return y
																	return {
																		name: e.target.value,
																		probability: x.probability
																	}
																})
																return newObj
															})
														}}
													/>
											}
										</td>
										<td style={{width: "25%"}}>
											{
												FullGiftList.length===i+1?x.probability.toString():
													<FormControl style={{padding: "0px 4px"}} type="text" value={x.probability}
														onChange={(e)=>{
															setGiftList((old)=>{
																const newObj = Object.assign({}, old)
																newObj[selectGiftListID] = old[selectGiftListID].map((y)=>{
																	if (x !== y) return y
																	const value = parseFloat(e.target.value)
																	return {
																		name: x.name,
																		probability: isNaN(value)||value.toString()!==e.target.value?e.target.value:parseFloat(e.target.value)
																	}
																})
																return newObj
															})
														}}
													/>
											}
										</td>
										<td>
											<div className="d-grid">
											{
												FullGiftList.length===i+1?"":
													<Button style={{padding: 0}} variant="danger" onClick={()=>{
														setGiftList((old)=>{
															const newObj = Object.assign({}, old)
															newObj[selectGiftListID] = old[selectGiftListID].filter((y)=>{return x!==y })
															return newObj
														})
													}}>删除</Button>
											}
											</div>
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</Table>
		)
	}, [selectGiftListID, FullGiftList])

	return useMemo(()=>{
		return (
			<div id="gift-panel">
				<InputGroup className="mb-2">
					<InputGroup.Text>当前列表</InputGroup.Text>
					<FormSelect value={selectGiftListID??undefined} onChange={(e)=>{
						setSelectGiftListID(e.target.value)
						if (localStorage.giftList)
							setGiftList(JSON.parse(localStorage.giftList))
					}}>
						{
							Object.keys(giftList).map((x, i)=>{
								return <option key={i} value={x}>{x}</option>
							})
						}
					</FormSelect>
					<Button onClick={()=>{setShowAddList(true)}}>添加</Button>
					<Button variant="danger" onClick={()=>{
						setSelectGiftListID(null)
						setGiftList((old)=>{
							const newObj = Object.assign({}, old)
							delete(newObj[selectGiftListID])
							return newObj
						})
					}}>
						删除
					</Button>
				</InputGroup>
				<Modal show={showAddList} onHide={()=>{setShowAddList(false)}}>
					<ModalHeader closeButton>
						<ModalTitle>添加列表</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Form onSubmit={(e)=>{
							e.preventDefault();
							e.stopPropagation();
							const formData = new FormData(e.currentTarget)
							const listName = formData.get("listName")
							if (Object.keys(giftList).findIndex((x)=>x===listName) !== -1){
								window.alert("此列表名称已存在")
								return
							}
							setGiftList((old)=>{
								const newObj = Object.assign({}, localStorage.giftList?JSON.parse(localStorage.giftList):old)
								newObj[listName] = []
								return newObj
							})
							setSelectGiftListID(listName)
							setShowAddList(false)
						}}>
							<FormGroup className="mb-2">
								<FormLabel>列表名称</FormLabel>
								<FormControl type="text" name="listName"/>
							</FormGroup>
							<Button type="submit">新增</Button>
						</Form>
					</ModalBody>
				</Modal>
				{GiftControlTable}
			</div>
		)
	}, [showAddList, selectGiftListID, giftList, GiftControlTable])
}

export default GiftPanel