import { useState, useMemo, useEffect } from "react";
import { Col, Container, Row, Tabs, Tab } from "react-bootstrap";

import NavBar from "../components/Navbar";
import GiftPanel from "./LuckyDrawOfflinePage/GiftPanel";
import HistoryPanel from "./LuckyDrawOfflinePage/HistoryPanel";
import LuckyDrawPanel from "./LuckyDrawOfflinePage/LuckyDrawPanel";
import ParticipantPanel from "./LuckyDrawOfflinePage/ParticipantPanel";


function LuckyDrawOfflinePage(){
    const [configTab, setConfigTab] = useState("gift")

    const [participantList, setParticipantList] = useState(localStorage.participantList?JSON.parse(localStorage.participantList):{})
    const [giftList, setGiftList] = useState(localStorage.giftList?JSON.parse(localStorage.giftList):{})
    const [history, setHistory] = useState(localStorage.history?JSON.parse(localStorage.history):[])

    const [selectParticipantListID, setSelectParticipantListID] = useState(null)
    const [selectGiftListID, setSelectGiftListID] = useState(null)

    useEffect(()=>{
        if (selectGiftListID === null && Object.keys(giftList).length > 0)
            setSelectGiftListID(Object.keys(giftList)[0])
        
        let isAllAreNumber = true
        Object.values(giftList).forEach((v)=>{
            v.forEach((x)=>{
                if (typeof(x.probability) !== "number")
                    isAllAreNumber = false
            })
        })
        if (isAllAreNumber)
            localStorage.setItem("giftList", JSON.stringify(giftList))
    }, [giftList, selectGiftListID])

    useEffect(()=>{
        if (selectParticipantListID === null && Object.keys(participantList).length > 0)
            setSelectParticipantListID(Object.keys(participantList)[0])
        
        let isAllAreNumber = true
        Object.values(participantList).forEach((v)=>{
            v.forEach((x)=>{
                if (typeof(x.times) !== "number")
                    isAllAreNumber = false
            })
        })
        if (isAllAreNumber)
            localStorage.participantList = JSON.stringify(participantList)
    }, [selectParticipantListID, participantList])

    useEffect(()=>{
        localStorage.history = JSON.stringify(history)
    }, [history])

    return useMemo(()=>{
        return (
            <>
                <NavBar/>
                <Container fluid height="auto" style={{backgoundColor: "#CCCCCC"}}>
                    <Row style={{display: "flex"}}>
                        <Col md={5} lg={4} xxl={3}>
                            <Tabs activeKey={configTab} onSelect={(key)=>{setConfigTab(key)}}>
                                <Tab eventKey="participant" title="参与列表" style={{paddingTop: 8, paddingLeft: 4, paddingRight: 4}}>
                                    <ParticipantPanel
                                        participantList={participantList} setParticipantList={setParticipantList}
                                        selectParticipantListID={selectParticipantListID} setSelectParticipantListID={setSelectParticipantListID}
                                    />
                                </Tab>
                                <Tab eventKey="gift" title="奖品列表" style={{paddingTop: 8, paddingLeft: 4, paddingRight: 4}}>
                                    <GiftPanel
                                        giftList={giftList} setGiftList={setGiftList}
                                        selectGiftListID={selectGiftListID} setSelectGiftListID={setSelectGiftListID}
                                    />
                                </Tab>
                            </Tabs>
                        </Col>
                        <Col md={7} lg={8} xxl={9} id="">
                            <LuckyDrawPanel
                                giftList={giftList} history={history} setHistory={setHistory}
                                participantList={participantList} setParticipantList={setParticipantList}
                            />
                            <HistoryPanel history={history} setHistory={setHistory}/>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }, [configTab, participantList, giftList, history, selectParticipantListID, selectGiftListID])
}

export default LuckyDrawOfflinePage
