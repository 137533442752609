import { useMemo } from "react";
import { Container, Table, Button } from "react-bootstrap";

function HistoryPanel({history=[], setHistory}){
    return useMemo(()=>{
        return (
            <Container fluid>
                <h2>抽奖结果</h2>
                <Table bordered striped hover>
                    <thead>
                        <tr>
                            <th>时间</th>
                            <th>参与者</th>
                            <th>奖品</th>
                            <td>
                                <div className="d-grid">
                                    <Button variant="danger" style={{padding: 0}} onClick={()=>{ setHistory([]) }}>清空</Button>
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {   history.length===0?<tr><td colSpan={4}><center>暂无记录</center></td></tr>:
                            history.map((x, i)=>{
                                const dateObj = new Date(x.time)
                                const date = dateObj.getFullYear() + "年" + ("0"+(dateObj.getMonth()+1)).slice(-2) + "月" + ("0"+dateObj.getDate()).slice(-2) + "日"
                                const time = ("0"+dateObj.getHours()).slice(-2) + "時" + ("0"+dateObj.getMinutes()).slice(-2) + "分" + ("0"+dateObj.getSeconds()).slice(-2) + "秒"

                                return (
                                    <tr key={i}>
                                        <td style={{width: "20%", minWidth: 250}}>{date + " " + time}</td>
                                        <td>{x.participant}</td>
                                        <td>{x.gift}</td>
                                        <td>
                                            <div className="d-grid">
                                                <Button variant="danger" style={{padding: 0}} onClick={()=>{
                                                    setHistory((old)=>{
                                                        return old.filter((y)=>{ return x !== y})
                                                    })
                                                }}>
                                                    删除
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }).reverse()
                        }
                    </tbody>
                </Table>
            </Container>
        )
    }, [history])
}

export default HistoryPanel