import { useState, useMemo } from "react";
import { Toast, ToastBody, ToastHeader } from "react-bootstrap";

function AutoHideToast({title="", time="", msg="", closeDelay=2000}){
    const [show, setShow] = useState(true)

    return useMemo(()=>{
        return (
            <Toast show={show} onClose={()=>{ setShow(false) }} autohide delay={closeDelay}>
                <ToastHeader>
                    <strong className="me-auto">{title}</strong>
                    <small>{time}</small>
                </ToastHeader>
                <ToastBody>
                    {msg}
                </ToastBody>
            </Toast>
        )
    }, [show, title, time, msg, closeDelay])
}

export default AutoHideToast