import { useState, useEffect, useMemo, useRef } from "react";
import { Button, Card, Container, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";

import NavBar from "../components/Navbar";
import request from "../functions/request";

function SystemNotOpen(){

    useEffect(()=>{
        if (localStorage.queue_id)
            delete(localStorage.queue_id)
    }, [])

    return (
        <Card>
            <Card.Header>排队系统</Card.Header>
            <Card.Body>
                <Card.Title>
                    排队系统未开启
                </Card.Title>
                <Card.Text>
                    请稍后再来
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

function JoinQueue({update, setStatus, setQueueInfo}){
    const [errMsg, setErrMsg] = useState(null)
    const [queueLength, setQueueLength] = useState({length: null, max: null, count: null})

    const currUpdate = useRef(null)
    useEffect(()=>{
        if (currUpdate.current === update) return
        currUpdate.current = update
        if (update % 5 === 0){
            request.post(window.location.protocol+"//"+window.location.hostname+"/api/queue/position", {queue_id: localStorage.queue_id??"lengthOnly"}).then((resp)=>{
                if (resp.status === false){
                    setStatus(-1)
                    return;
                }
                if (resp.isCurrent || resp.position !== -1){
                    const offset = resp.isCurrent?2:1
                    request.post(window.location.protocol+"//"+window.location.hostname+"/api/queue/load", {queue_id: localStorage.queue_id}).then((resp)=>{
                        setQueueInfo(resp)
                        setStatus((now)=>now+offset)
                    })
                    return
                }

                delete(localStorage.queue_id)
                setQueueLength({length: resp.length, max: resp.max, count: resp.count})
            })
        }
    }, [update, setQueueInfo, setStatus])

    return useMemo(()=>{
        if (queueLength.length === null){
            request.post(window.location.protocol+"//"+window.location.hostname+"/api/queue/position", {queue_id: "lengthOnly"}).then((resp)=>{
                setQueueLength({length: resp.length})
            })
        }

        const quotas = queueLength.max-queueLength.count-queueLength.length

        return (
            <Card>
                <Card.Header>排队系统</Card.Header>
                <Card.Body>
                    <Card.Title>参与表格</Card.Title>
                    <Card.Subtitle>剩余{queueLength.max-queueLength.count-queueLength.length}个名额 当前排队人数: {queueLength.length}</Card.Subtitle>
                    <Form onSubmit={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        const formData = new FormData(e.target)
                        const [username, loginInfo] = [formData.get("username").trim(), formData.get("loginInfo").trim()]
                        if (username === ""){
                            setErrMsg("用户名不可为空")
                            return
                        }
                        if (loginInfo === ""){
                            setErrMsg("登入资讯不可为空")
                            return
                        }
                        request.post(window.location.protocol+"//"+window.location.hostname+"/api/queue/join", {username, loginInfo}).then((resp)=>{
                            if (resp.status){
                                setStatus((now)=>now+1)
                                localStorage.queue_id = resp.queue_id
                                setQueueInfo(resp)
                            }
                            else{
                                setErrMsg(resp.reason)
                            }
                        })
                    }}>
                            <FormGroup className="mt-2 mb-2">
                                <FormLabel>直播间内的名称</FormLabel>
                                <FormControl type="text" name="username" placeholder="" onChange={()=>{setErrMsg(null)}}/>
                            </FormGroup>
                            <FormGroup className="mb-2">
                                <FormLabel>登入资讯</FormLabel>
                                <FormControl type="text" name="loginInfo" placeholder="手机号: xxxxxxxxxxx" onChange={()=>{setErrMsg(null)}}/>
                            </FormGroup>
                            <p style={{color: "red"}}>{errMsg}</p>
                            <p style={{color: "grey"}}>当已经排到你或清空队伍时，系统会直接清理资料，不会保留任何信息。</p>
                            <Button type="submit" disabled={quotas === 0} variant={quotas>0?"primary":"secondary"}>
                                {quotas>0?"排队":"没有名额了"}
                            </Button>
                    </Form>
                </Card.Body>
            </Card>
        )
    }, [errMsg, setStatus, setQueueInfo, queueLength])
}

function Waiting({update, queueInfo, setStatus}){
    const [queueLength, setQueueLength] = useState({position: null, length: null})

    useEffect(()=>{
        if (update % 3 === 0)
            request.post(window.location.protocol+"//"+window.location.hostname+"/api/queue/position", {queue_id: queueInfo.queue_id}).then((resp)=>{
                if (resp.status){
                    if (resp.isCurrent)
                        setStatus((now)=>now+1)
                    else if (resp.position === -1)
                        setStatus(0)
                    setQueueLength({length: resp.length, position: resp.position})
                }
            })
    }, [queueInfo, update, setStatus])

    return useMemo(()=>{
        return (
            <Card>
                <Card.Header>排队系统</Card.Header>
                <Card.Body>
                    <Card.Title>排队中</Card.Title>
                    <Card.Text>
                        当前排队人数: {queueLength.length}<br/>
                        你当前排在第 {(queueLength.position!==null?(queueLength.position+1):"")} 位
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }, [queueLength])
}

function SetAuthcode({update, queueInfo, setStatus}){
    const [errMsg, setErrMsg] = useState(null)

    useEffect(()=>{
        if (update % 5 === 0)
            request.post(window.location.protocol+"//"+window.location.hostname+"/api/queue/position", {queue_id: queueInfo.queue_id}).then((resp)=>{
                if (resp.status){
                    if (!resp.isCurrent)
                        setStatus((now)=>now+1)
                }
            })
    }, [queueInfo, update, setStatus])

    return useMemo(()=>{
        return (
            <Card>
                <Card.Header>排队系统</Card.Header>
                <Card.Body>
                    <Card.Title>提供验证码</Card.Title>
                    <Form onSubmit={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        const formData = new FormData(e.target)
                        const authcode = formData.get("authcode").trim()
                        if (authcode === ""){
                            setErrMsg("验证码不可为空")
                            return
                        }
                        request.post(window.location.protocol+"//"+window.location.hostname+"/api/queue/authcode", {queue_id: queueInfo.queue_id, authcode: authcode}).then((resp)=>{
                            if (resp.status === false){
                                setStatus(0)
                                return
                            }
                            setErrMsg("已提交，有需要可重新提交")
                        })
                    }}>
                        <FormGroup className="mt-2 mb-2">
                            <FormLabel>验证码</FormLabel>
                            <FormControl type="text" name="authcode" onChange={()=>{setErrMsg(null)}}/>
                        </FormGroup>
                        <p style={{color: "red"}}>{errMsg}</p>
                        <Button type="submit">提交</Button>
                    </Form>
                </Card.Body>
            </Card>
        )
    }, [errMsg, queueInfo, setStatus])
}

function Done(){
    return useMemo(()=>{
        return (
            <Card>
                <Card.Header>排队系统</Card.Header>
                <Card.Body>
                    <Card.Title>感谢参与哦</Card.Title>
                    <Card.Text>
                        欢迎下次再来！
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }, [])
}

function QueuePage(){
    const [status, setStatus] = useState(0)
    const [queueInfo, setQueueInfo] = useState({queue_id: null, username: null, loginInfo: null})
    const [update, setUpdate] = useState(0)

    // useEffect(()=>{
    //     statusRef.current = status
    // }, [status])

    const timer = useRef(null)
    useEffect(()=>{
        if (timer.current === null){
            // 设定计时器，每1秒更新一次
            timer.current = setInterval(()=>{
                setUpdate((old)=>(old + 1)%3000)
            }, 1000)
        }
    }, [])

    useEffect(()=>{
        // 检查系统是否开启
        if (update % 5 === 0)
            request.get(window.location.protocol+"//"+window.location.hostname+"/api/queue/opening").then((resp)=>{
                if (resp.status) {
                    setStatus((now)=>{ return now===-1?0:now })
                    return
                }
                setStatus(-1)
                setQueueInfo({queue_id: null, username: null, loginInfo: null})
            })
    }, [update])

    return useMemo(()=>{
        return (
            <>
                <NavBar/>
                <Container fluid style={{margin: "auto", maxWidth: "600px"}}>
                    {
                        status === -1 ? <SystemNotOpen/> : // 系统未开启
                        status === 0  ? <JoinQueue update={update} setStatus={setStatus} setQueueInfo={setQueueInfo}/> : // 填入资料
                        status === 1  ? <Waiting update={update} queueInfo={queueInfo} setStatus={setStatus}/> : // 等待
                        status === 2  ? <SetAuthcode update={update} queueInfo={queueInfo} setStatus={setStatus}/> : // 填入验证码
                        <Done/> // 结束
                    }
                </Container>
            </>
        )
    }, [status, update, queueInfo])
}

export default QueuePage