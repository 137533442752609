import { useState, useEffect, useMemo, useRef } from "react"
import request from "../../../functions/request"

import { Card, Container, Row, Col, Button } from "react-bootstrap"
import CheckDraw from "./CheckDraw"
import DangerBtn from "../../../components/DangerBtn"

function EndedDraw({currentWS}){
    const [drawList, setDrawList] = useState([])
    const [checkingDrawID, setCheckingDrawID] = useState(-1)

    const requestSended = useRef(false)
    const requestRecv = useRef(false)

    useEffect(()=>{
        if (!requestSended.current){
            requestSended.current = true
            fetch(window.location.protocol+"//"+window.location.hostname+"/api/admin/luckydraw/drawList").then(r=>r.json()).then(resp=>{
                if (resp.status){
                    requestRecv.current = true
                    setDrawList(resp.luckydraws.filter((x)=>{
                        return x.is_start === 1 && x.is_end === 1
                    }))
                }
            })
        }
    }, [])

    return useMemo(()=>{
        if (checkingDrawID !== -1){
            return <CheckDraw checkingDrawID={checkingDrawID} setCheckingDrawID={setCheckingDrawID} ended={true} currentWS={currentWS}/>
        }

        return (
            <Container fluid>
                {
                    drawList.length===0?<h3 style={{textAlign: "center"}}>{requestRecv.current?"没有已结束的抽奖活动":"正在查询。。。"}</h3>:
                    <Row>
                        {
                            drawList.map((x)=>{
                                return (
                                    <Col key={x.id} md={6} xl={4}>
                                        <Card className="mb-2" style={{textAlign: "left"}}>
                                            <Card.Body>
                                                <Card.Title>活动名:&nbsp;{x.name}</Card.Title>
                                                <br/>
                                                <div style={{display: "flex"}}>
                                                    <div style={{flex: 1, display: "flex", gap: 8}}>
                                                        <Button onClick={()=>{ setCheckingDrawID(x.id) }}>查看</Button>
                                                    </div>
                                                    <div style={{textAlign: "right"}}>
                                                        <DangerBtn name="删除" dangerMessage="删除之后所有相关记录都会删除噢，确定吗？" onClick={()=>{
                                                            request.post(window.location.protocol+"//"+window.location.hostname+"/api/admin/luckydraw/delDraw", {id: x.id}).then(resp=>{
                                                                if (resp.status){
                                                                    setDrawList((old)=>{
                                                                        return old.filter((y)=>{return y !== x})
                                                                    })
                                                                }
                                                                else{
                                                                    window.alert(resp.reason)
                                                                    window.location.reload()
                                                                }
                                                            })
                                                        }}/>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                }
            </Container>
        )
    }, [drawList, checkingDrawID, currentWS])
}

export default EndedDraw