import { useState, useEffect, useMemo, useRef, useCallback } from "react"
import { useParams } from "react-router-dom"
import request from "../functions/request";
import timehandle from "../functions/timehandle";

import { Card, Container, Row, Col, Table, Modal, ModalHeader, ModalBody, ModalTitle } from "react-bootstrap"
import { LuckyWheel } from '@lucky-canvas/react'

import NavBar from "../components/Navbar"

function LuckyDrawPage(){
	const {draw_id, participant_id, auth_key} = useParams();
	const [loading, setLoading] = useState(true)

	const [eventName, setEventName] = useState("")
	const [participant, setParticipant] = useState(null)
	const [giftList, setGiftList] = useState([])
	const [records, setRecords] = useState([])

	const participantRef = useRef(null)
	const giftListRef = useRef([])

	const requestSended = useRef(false)

	const updateInfo = useCallback((participantOnly)=>{
		requestSended.current = true
		request.post(window.location.protocol+"//"+window.location.hostname+"/api/luckydraw/loadInfo", {
			draw_id: draw_id,
			participant_id: participant_id,
			auth_key: auth_key,
			participantOnly: participantOnly
		}).then(resp=>{
			setLoading(false)
			setEventName(resp.eventName)
			setParticipant(resp.participant)
			setRecords(resp.records)

			participantRef.current = resp.participant

			if (!participantOnly){
				setGiftList(resp.gifts)
				giftListRef.current = resp.gifts
			}
		})
	}, [draw_id, participant_id, auth_key])

	useEffect(()=>{
		if (!requestSended.current)
			updateInfo(false)
	}, [draw_id, participant_id, auth_key, updateInfo])
	
	const participantInfo = useMemo(()=>{
		if (participant === null) return null

		return (
			<Card className="mb-2">
				<Card.Header>欢迎，{participant.name}</Card.Header>
				<Card.Body>
					<span>活动名称:&nbsp;{eventName}</span><br/><br/>
					<span>你剩余的抽奖次数:&nbsp;{participant.times}</span>
				</Card.Body>
			</Card>
		)
	}, [participant, eventName])

	const giftsInfo = useMemo(()=>{
		return (
			<Card>
				<Card.Header>礼物列表</Card.Header>
				<Card.Body>
					<Table bordered hover>
						<thead>
							<tr>
								<th>奖品</th>
								<th>得奖机率(%)</th>
							</tr>
						</thead>
						<tbody>
							{
								giftList.map((x)=>{
									return (
										<tr key={x.id}>
											<td>{x.name}</td>
											<td>{x.probability}</td>
										</tr>
									)
								})
							}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		)
	}, [giftList])

	const recordTable = useMemo(()=>{
		return (
			<Card>
				<Card.Header>抽奖记录</Card.Header>
				<Card.Body>
					<Table bordered hover>
						<thead>
							<tr>
								<th>系统编号</th>
								<th>时间</th>
								<th>奖品</th>
							</tr>
						</thead>
						<tbody>
							{
								records.map((x)=>{
									const gift = giftList.find((y)=>{return y.id === x.gift_id})

									return (
										<tr key={x.id}>
											<td>{x.id}</td>
											<td>{timehandle.epochToString(x.time, timehandle.TF.ymdhms)}</td>
											<td>{gift.name}</td>
										</tr>
									)
								}).reverse()
							}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		)
	}, [records, giftList])

	const [notification, setNotification] = useState(null)

	const respTmp = useRef(null)
	const luckydraw = useRef(null)
	const [draw_prizes, draw_blocks, draw_buttons] = useMemo(()=>{
        const colors = ["#ffced9", "#cff0ec", "#d2eddd", "#c2d3fd", "#fecdc1", "#eee8d1"]

        return [
            giftList.map((x, i)=>{
                return {
                    background: (giftList.length-1===i?"#e2e1e1":colors[i%colors.length]),
                    fonts: [{text: x.name}],
                    originIndex: i
                }
            }),
            [
                { padding: '10px', background: '#ffc0cb' }
            ],
            [
                { radius: '30%', background: '#617df2' },
                { radius: '27.5%', background: '#afc8ff' },
                {
                    radius: '25%', background: '#869cfa',
                    pointer: true,
                    fonts: [{ text: '开始', top: '-10px' }]
                }
            ]
        ]
    }, [giftList])
	const draw_onStart = useCallback(()=>{
        if (luckydraw.current === null || participantRef.current === null || giftListRef.current.length === 0) return
        if (participantRef.current.times <= 0) {
            setNotification(
				<Modal show={true} onHide={()=>{ setNotification(null) }}>
					<ModalHeader closeButton>
						<ModalTitle>没有抽奖机会了</ModalTitle>
					</ModalHeader>
					<ModalBody>
						你的抽奖机会用完咯~下次再来参加吧！
					</ModalBody>
				</Modal>
			)
            return
        }

        luckydraw.current.play()
		respTmp.current = null
		request.post(window.location.protocol+"//"+window.location.hostname+"/api/luckydraw/runDraw", {
			draw_id: draw_id,
			participant_id: participant_id,
			auth_key: auth_key
		}).then(resp=>{
			if (resp.status){
				respTmp.current = resp
				luckydraw.current.stop(resp.record.gift_id)
				participantRef.current.times = resp.times
			}
			else{
				luckydraw.current.stop(0)
				setNotification(
					<Modal show={true} onHide={()=>{
						window.location.reload()
						setNotification(null)
					}}>
						<ModalHeader closeButton>
							<ModalTitle>出错了</ModalTitle>
						</ModalHeader>
						<ModalBody>
							{resp.reason}
						</ModalBody>
					</Modal>
				)
			}
		})
    }, [draw_id, participant_id, auth_key])
	const draw_onEnd = useCallback((e)=>{
		if (respTmp.current){
			const gift = giftListRef.current.find((x)=>{return x.id === respTmp.current.record.gift_id})
			setRecords((old)=>[...old, respTmp.current.record])
			setParticipant((old)=>{
				const newObj = JSON.parse(JSON.stringify(old))
				newObj.times = respTmp.current.times
				return newObj
			})
			setNotification(
				<Modal show={true} onHide={()=>{ setNotification(null) }}>
					<ModalHeader closeButton>
						<ModalTitle>
							{gift.name==="奖品飞咯"?gift.name:"你抽中奖品啦"}
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						{	gift.name==="奖品飞咯"?"这么低的概率都让你抽到了？没办法，奖品飞喽～小倒霉蛋^_^":
							("你成功抽中了 " + gift.name + " 噢！")
						}
					</ModalBody>
				</Modal>
			)

			setTimeout(()=>{
				setNotification(null)
			}, 3000)
		}
    }, [])

	const luckydrawPanel = useMemo(()=>{
		if (giftList.length === 0) return null

		return (
			<Card className="mb-2">
				<Card.Header>抽奖面板</Card.Header>
				<Card.Body>
					<div style={{margin: "auto", width: "fit-content"}}>
						<LuckyWheel ref={luckydraw}
							width="25rem" height="25rem"
							blocks={draw_blocks} prizes={draw_prizes} buttons={draw_buttons}
							onStart={draw_onStart} onEnd={draw_onEnd}
						/>
					</div>
				</Card.Body>
			</Card>
		)
	}, [giftList, draw_prizes, draw_blocks, draw_buttons, draw_onStart, draw_onEnd])

	return useMemo(()=>{
		if (loading) return <h3>加载中。。。</h3>

		return (
			<>
				<NavBar/>
				<Container fluid>
					<Row>
						<Col className="mb-2" lg={3}>
							{participantInfo}
							{giftsInfo}
						</Col>
						<Col lg={9}>
							{luckydrawPanel}
							{recordTable}
						</Col>
					</Row>
				</Container>
				{notification}
			</>
		)
	}, [loading, participantInfo, giftsInfo, luckydrawPanel, recordTable, notification])
}

export default LuckyDrawPage